<template>
  <div>
    <div>
      <div class="page-head-css">
        <div style="padding-top: 10px">
          <a-row>
            <a-col :span="8">
              <div style="float: left; margin-left: 10px">
                <span>仪表类型: </span>
                <a-select
                  v-model="selectMeterModel"
                  placeholder="请选择仪表类型"
                  allowClear
                  style="width: 150px"
                  @change="selectMeterModelChange"
                >
                  <a-select-option
                    v-for="item in meterModelData"
                    :key="item.id"
                  >
                    {{ item.meterName }}
                  </a-select-option>
                </a-select>
              </div>
            </a-col>
            <a-col :span="8">
              <div style="display: flex; justify-content: center">
                <a-badge status="success" />
                在线
                <a-badge status="warning" style="margin-left: 10px" />
                异常
                <a-badge status="default" style="margin-left: 10px" />
                离线
              </div>
            </a-col>
            <a-col :span="8">
              <div style="float: right; margin-right: 10px">
                <span>农场基地: </span>
                <a-select
                  v-model="selectFarmArea"
                  placeholder="请选择农场基地"
                  allowClear
                  style="width: 150px"
                  @change="farmAreaSearchQuery"
                >
                  <a-select-option v-for="item in farmAreaList" :key="item.id">
                    {{ item.fullName }}
                  </a-select-option>
                </a-select>
                <!--<a-button style="margin-left: 12px" @click="chooseFarmArea">选择农场</a-button>-->
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
      <div style="margin: 0px 20px">
        <div style="margin-bottom: 8px"></div>
        <a-row :gutter="20">
          <a-col
            :xs="{ span: 24 }"
            :sm="{ span: 24 }"
            :md="{ span: 12 }"
            :lg="{ span: 12 }"
            :xl="{ span: 8 }"
            v-for="item in realTimeData"
            :key="item.id"
          >
            <a-card
              hoverable
              class="card-css"
              :style="{
                borderTop:
                  item.state === 'NORMAL'
                    ? '4px solid #52c41a'
                    : item.state === 'ABNORMAL'
                    ? '4px solid #E6A23C'
                    : '4px solid #909399',
              }"
              :key="item.id"
            >
              <span slot="title" style="font-size: 14px">{{
                item.measurePointFullName
              }}</span>
              <span slot="extra" style="color: #999">{{ item.time }}</span>
              <div style="height: 100%">
                <a-row
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    flex-wrap: wrap;
                  "
                >
                  <a-col
                    :span="8"
                    v-for="itemValue in item.meterCollectItemList"
                    :key="itemValue.collectItem"
                  >
                    <div class="crad-detail">
                      <div>
                        <span
                          :style="{
                            color:
                              item.state === 'OFFLINE'
                                ? '#909399'
                                : itemValue.state === 'NORMAL'
                                ? '#52c41a'
                                : '#E6A23C',
                            fontSize: '20px',
                          }"
                          v-if="itemValue.value / 1 == itemValue.value"
                        >
                          <countTo
                            :autoplay="true"
                            :startVal="0"
                            :endVal="itemValue.value"
                            :duration="1000"
                            :decimals="1"
                          >
                          </countTo>
                        </span>
                        <span
                          :style="{
                            color:
                              item.state === 'OFFLINE'
                                ? '#909399'
                                : itemValue.state === 'NORMAL'
                                ? '#52c41a'
                                : '#E6A23C',
                            fontSize: '20px',
                          }"
                          v-else
                        >
                          {{
                            itemValue.value == null ? "--" : itemValue.value
                          }}</span
                        >
                        <span
                          style="color: rgba(0, 0, 0, 0.45); font-size: 12px"
                          >{{ itemValue.unit }}</span
                        >
                      </div>
                      <span>{{ itemValue.describe }}</span>
                    </div>
                  </a-col>
                </a-row>
              </div>
            </a-card>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from "vue-count-to";

export default {
  name: "RealTimeData",
  components: {
    countTo,
  },
  data() {
    return {
      selectFarmArea: [],
      timer: "timer",
      farmAreaList: [],
      realTimeData: [],
      meterModelData: [],
      selectMeterModel: [],
    };
  },

  mounted() {
    this.timer = setInterval(this.getRealTimeData, 30000);
  },

  created() {
    this.getFarmAreaData();
    this.getAllMeterModelByOrganizationId();
    this.getRealTimeData();
  },

  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },

  methods: {
    selectMeterModelChange(value) {
      this.getRealTimeData();
    },
    getAllMeterModelByOrganizationId() {
      this.http.device.getAllMeterModelByOrganizationId().then((response) => {
        if (response.data.status === 200) {
          this.meterModelData = response.data.data;
        } else {
          this.$message.warning("数据获取失败");
        }
      });
    },
    getFarmAreaData() {
      this.http.farmArea.getFarmAreaList().then((response) => {
        if (response.data.status == 200) {
          this.farmAreaList = response.data.data;
        } else {
          this.$message.warning("数据获取失败");
        }
      });
    },
    getRealTimeData() {
      let params = {
        farmAreaId: this.selectFarmArea,
        meterModelId: this.selectMeterModel,
      };
      this.http.monitor.getRealTimeData(params).then((response) => {
        if (response.data.status == 200) {
          this.realTimeData = response.data.data;
        } else {
          this.$message.warning("数据获取失败");
        }
      });
    },
    farmAreaSearchQuery() {
      this.spinning = true;
      this.getRealTimeData();
    },
  },
};
</script>

<style scoped>
.card-css >>> .ant-card-head {
  border-bottom: 0px;
}

.card-css {
  width: 100%;
  height: 15rem;
  margin: 10px 0px;
}

.crad-detail {
  text-align: center;
  padding: 0 0px 20px 0px;
}

.card-css >>> .ant-card-body {
  padding: initial;
  width: 100%;
  height: 180px;
}

.real-data-card {
  margin: 20px;
  border: 1px solid #ebeef5;
  color: #303133;
  background-color: #fff;
  box-shadow: 0 1px 1px rgb(0 0 0 / 15%);
  border-radius: 2px;
}
</style>
